import { ArrowDropDown, Print } from "@mui/icons-material"
import { LinearProgress } from "@mui/material"

import moment from "moment"

import router from "next/router"

import { FC, useEffect, useMemo, useRef, useState } from "react"

import { useReactToPrint } from "react-to-print"
import { BasicDropdown, DropdownRef } from "src/components/common/dropdown"
import { Select, SelectOption } from "src/components/common/select"
import { Table, TableData } from "src/components/common/table"
import { create } from "src/helpers/bem"
import { getPage } from "src/helpers/pages"
import { formatPrice } from "src/helpers/price"
import { fetchWebshopOrders } from "src/queriesXRM/purchaseorder"
import { useBookings } from "src/states/bookings"
import { useLocale } from "src/states/locale"
import { TranslationMessages } from "src/translations"
import { HeaderProps, PageProps, PagesProps } from "src/types/SharedPageProps"
import { xRMApiExhibition, xRMApiPurchaseorder } from "src/types/xRM"

import styles from "./ShopOrders.module.scss"

const bem = create(styles, "ShopOrders")

export type OrderFilter = {
	exhibition: string,
	booking: string
}

export const defaultOrderFilter = {
	exhibition: 'all',
	booking: 'all'
}

export type ShopOrdersProps = {
	messages: TranslationMessages
	page: PageProps
	pages: PagesProps
	header : HeaderProps
}

export const ShopOrders: FC<ShopOrdersProps> = ({ messages, page, pages, header }) => {
	const locale = useLocale()
	const { bookings } = useBookings()
	const [ loading, setLoading ] = useState(false)
	const [ orders, setOrders ] = useState<xRMApiPurchaseorder[]>([])
	const [ filteredOrders, setFilteredOrders ] = useState<xRMApiPurchaseorder[]>([])
	const [ filter, setFilter ] = useState<OrderFilter>(defaultOrderFilter)
	const dropdownRef = useRef<DropdownRef>(null)
    const printRef = useRef(null)
	const m = messages.pages.shop.orders

	const webshopOrderDetailPage = useMemo(() => getPage("Webshop Order Detail Template", locale, pages),[pages, locale])

	useEffect(() => {
		setLoading(true)
		const fetchWebshopOrdersAsync = async () => setOrders(await fetchWebshopOrders())
		fetchWebshopOrdersAsync().finally(() => setLoading(false))
	}, [])

	useEffect(() => {
		setFilteredOrders(orders
			.filter(order => filter.exhibition === 'all'
				? order
				: order.exhibition?.id === filter.exhibition)
			.filter(order => filter.booking === 'all'
				? order
				: order.parentSalesorderId === filter.booking))
	}, [filter, orders])

	const handlePrint = useReactToPrint({
        content: () => printRef.current,
        documentTitle: m.print.title,
        copyStyles: true,
        pageStyle: `
            @media print {
                @page { size: a4 landscape; } 
                .pagebreak { page-break-before: always; }
            }
        `
    })

	const handleClickCell = async (orderNumber: string) => {
		const orderId = orders.find(order => order.orderNumber === orderNumber)?.id
		await router.push({
			pathname: webshopOrderDetailPage?.uri as string,
			query: {
				orderId
			}
		})
	}

	const renderTable = (data: TableData[]) =>
		<>
			{data.length > 0 ? (
				<div className={bem("table")}>
					<Table
						cellTranslations={m.table}
						data={data}
						handleClickCell={(_, name) => handleClickCell(name)}
						messages={messages}
						order="desc"
						orderBy="date"
					/>
				</div>
			) : (
				<p className={bem('nodata')}>
					{m.noData}
				</p>
			)}
		</>

	const ordersOnOwnAccount = filteredOrders.filter(order => order.isPurchaseorderOnOwnAccount)

	const ordersOnForeignAccount = filteredOrders.filter(order => !order.isPurchaseorderOnOwnAccount)

	const formatOrders = (items: xRMApiPurchaseorder[]): TableData[] =>
		items.map(order => ({
			name: order.orderNumber!,
			booking: bookings.find(booking => booking.id === order.parentSalesorderId!)?.name!,
			exhibition: order.exhibition?.name ?? "",
			date: moment(order.registrationDate!).format("lll"),
			status: order.phase,
			total: order.totalAmount !== 0 ? formatPrice(order.totalAmount, locale) : m.table.total.noAmount
		}))

	let exhibitionOptions: SelectOption[] = orders
		.filter(order => order.exhibition?.id && order.exhibition?.name)
		.reduce<xRMApiExhibition[]>((acc, cur) => {
			if (!acc.some(exhibition => exhibition.id === cur.exhibition!.id))
				acc.push(cur.exhibition!)
			return acc
		}, [])
		.map(exhibition => ({
			key: exhibition!.id!,
			value: exhibition!.name!,
		}))

	exhibitionOptions = [
		{
			key: 'all',
			value: m.select.exhibitions.items.all
		},
		...exhibitionOptions,
	]

	let bookingOptions: SelectOption[] = orders
		.filter(order => filter.exhibition === 'all' ? order : order.exhibition?.id === filter.exhibition)
		.filter(order => order.parentSalesorderId)
		.reduce<xRMApiPurchaseorder[]>((acc, cur) => {
			if (!acc.some(order => order.parentSalesorderId === cur.parentSalesorderId))
				acc.push(cur)
			return acc
		}, [])
		.map(order => ({
			key: order.parentSalesorderId!,
			value: bookings.find(booking => booking.id === order.parentSalesorderId!)?.name!
		}))

	bookingOptions = [
		{
			key: 'all',
			value: m.select.bookings.items.all
		},
		...bookingOptions
	]

	const dropdownButton =
        <span>
            <ArrowDropDown />
            {m.dropdown.label}
        </span>

    const dropdownItems = [
        {
            sortBy: 0,
            key: "print",
            label: m.dropdown.print,
            icon: <Print />,
            onClick: () => handlePrint(),
        },
    ]

	if (loading) return <LinearProgress color="primary" />

	return (
		<div className={bem()}>
			<div className={bem("header")}>
				<h1>{page?.data?.title}</h1>
				<BasicDropdown
					ref={dropdownRef}
					button={dropdownButton}
					items={dropdownItems}
				/>
			</div>
			<Select
				items={exhibitionOptions}
				label={m.select.exhibitions.label}
				value={filter.exhibition}
				onChange={(e, _) => setFilter({
					...filter,
					exhibition: e.target.value as string,
					booking: 'all'
				})}
			/>
			<Select
				items={bookingOptions}
				label={m.select.bookings.label}
				value={filter.booking}
				onChange={(e, _) => setFilter({
					...filter,
					booking: e.target.value as string
				})}
			/>
			<div ref={printRef}>
				<h6>
					{m.tabs.orderOnOwnAccount}
				</h6>
				{renderTable(formatOrders(ordersOnOwnAccount))}
				<h6>
					{m.tabs.orderOnForeignAccount}
				</h6>
				{renderTable(formatOrders(ordersOnForeignAccount))}
			</div>
		</div>
	)
}
import { CardContent } from "@mui/material"
import { FC } from "react"
import { Card } from "src/components/common/card"
import { create } from "src/helpers/bem"

import { useTranslation } from "src/hooks/translation"

import { useCountries } from "src/states/countries"
import { useLocale } from "src/states/locale"
import { xRMApiPurchaseorder } from "src/types/xRM"

import styles from "./ShopOrderDetailsAddress.module.scss"

const bem = create(styles, "ShopOrderDetailsAddress")

export type ShopOrderDetailsAddressProps = {
	order: xRMApiPurchaseorder
}

export const ShopOrderDetailsAddress: FC<ShopOrderDetailsAddressProps> = ({ order }) => {
	const locale = useLocale()
	const translation = useTranslation()
	const countries = useCountries()
	const m = translation.messages.pages.shop.orders.details.address

	const billingAddress = {
		customer: order.company?.name ?? '',
		street: `${order.company?.addressLine1 ?? ''}\n${order.company?.addressLine2 ?? ''}`,
		zipcode: order.company?.postalcode ?? '',
		city: order.company?.city ?? '',
		country: (
			locale === 'de'
				? countries.find(c => c.key === order.company?.addressCountryId)?.germanName
				: countries.find(c => c.key === order.company?.addressCountryId)?.englishName
		) ?? '',
		email: order.company?.mail ?? ''
	}

	const shippingAddress = {
		customer: order.debitor?.name ?? '',
		street: order.debitor?.street ?? '',
		zipcode: '',
		city: order.debitor?.city ?? '',
		country: (
			locale === 'de'
				? countries.find(c => c.key === order.debitor?.countryId)?.germanName
				: countries.find(c => c.key === order.debitor?.countryId)?.englishName
		) ?? '',
		email: ''
	}

	return (
		<Card>
			<CardContent>
				<div className={bem()}>
					<div className={bem('summary')}>
						<div className={bem('summary__line')}>
							<div className={bem('summary__line__block')}>
								<div>{m.billingAddress}</div>
								<div>{billingAddress.customer}</div>
								<div>{billingAddress.street}</div>
								<div>{billingAddress.zipcode} {billingAddress.city}</div>
								<div>{billingAddress.country}</div>
							</div>
							<div className={bem('summary__line__block')}>
								<div>{m.shippingAddress}</div>
								<div>{shippingAddress.customer}</div>
								<div>{shippingAddress.street}</div>
								<div>{shippingAddress.zipcode} {shippingAddress.city}</div>
								<div>{shippingAddress.country}</div>
							</div>
						</div>
						<div className={bem('summary__line')}>
							<div className={bem('summary__line__block')}>
								<div>{m.email}</div>
								<div>{billingAddress.email}</div>
							</div>
						</div>
					</div>
				</div>
			</CardContent>
		</Card>
	)
}